import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ"; 
import HeaderHosting from "../components/HeaderHosting";
import { shonaSQLdata } from '../database/shonaSQL';
import { MemoryStick, Cpu, Waypoints, Database, Save, MapPinned } from 'lucide-react';

const ShonaSQLPage = () => {
    const { title, description, packages, faq } = shonaSQLdata[0] || {};

    if (!packages) {
        return (
            <div>
                <Navbar />
                <div className="container mx-auto py-10 text-center">
                    <h2 className="text-2xl font-bold">Error: Data hosting tidak ditemukan</h2>
                </div>
                <FAQ faqData={faq} />
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <HeaderHosting title={title} description={description} />
            <div className="container mx-auto py-10">
                <h2 className="text-2xl font-bold text-center mb-6 text-white">Our Hosting Packages</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 px-4">
                    {packages.map((pkg, index) => (
                        <div
                            key={index}
                            onClick={() => window.location.href = 'https://discord.gg/akashonacloud'}
                            className="bg-gray-800 p-6 rounded-2xl shadow-lg transition transform hover:scale-105 hover:bg-gray-700 hover:shadow-xl hover:translate-y-[-5px] hover:bg-opacity-80 cursor-pointer"
                        >
                            <h3 className="text-2xl font-bold text-yellow-500">{pkg.name}</h3>
                            <div className="flex items-center text-white mt-2">
                                <MemoryStick className="mr-2 text-md" size={20} />
                                <p>RAM: {pkg.ram} MB</p>
                            </div>
                            <div className="flex items-center text-white mt-2">
                                <Cpu className="mr-2" size={20} />
                                <p>CPU: {pkg.cpu_percent}%</p>
                            </div>
                            <div className="flex items-center text-white mt-2">
                                <MapPinned className="mr-2" size={20} />
                                <p>Location: {pkg.datacenter}</p>
                            </div>
                            <div className="flex items-center text-white mt-2">
                                <Waypoints className="mr-2" size={20} />
                                <p>Max Connection: {pkg.maxconn}</p>
                            </div>
                            <div className="flex items-center text-white mt-2">
                                <Database className="mr-2" size={20} />
                                <p>Max Databases: {pkg.maxdb}</p>
                            </div>
                            <p className="text-xl mt-2 font-bold text-white">IDR {pkg.price}</p>
                        </div>
                    ))}
                </div>
            </div>
            <FAQ faqData={faq} />
            <Footer />
        </div>
    );
};

export default ShonaSQLPage;
