export const faqmain = [
    {
      faq: [
        {
          question: "Berapa biaya pembelian pertama?",
          answer: "Tidak ada biaya pembelian pertama, perpanjangan bisa dilakukan kapan saja dengan biaya yang sama seperti saat pembelian pertama (terkecuali pada harga diskon).",
        },
        {
          question: "Apakah ada garansi?",
          answer: "Ya, kami memberikan garansi untuk semua produk di website ini.",
        },
        {
          question: "Bagaimana cara order?",
          answer: "Kami menyediakan layanan order otomatis melalui website controlcenter.",
        },
        {
          question: "Customer Support dimana?",
          answer: "Kami tersedia malalui E-mail, Whatsapp, ataupun Discord. Selengkapnya ada di halaman contact.",
        },
      ]
    }
  ];
  