export const termsofservice = {
    title: {
        en: "Terms of Service - Hosting Services",
        id: "Syarat dan Ketentuan - Layanan Hosting"
    },
    introduction: {
        en: "Welcome to Akashona Cloud's Terms of Service (TOS). This document serves as a core agreement between you and us, detailing the terms under which our services are provided.",
        id: "Selamat datang di Syarat dan Ketentuan (TOS) Akashona Cloud. Dokumen ini berfungsi sebagai perjanjian inti antara Anda dan kami, yang menguraikan ketentuan-ketentuan dalam penyediaan layanan kami."
    },
    sections: [
        {
            title: {
                en: "1. Resource Usage",
                id: "1. Penggunaan Sumber Daya"
            },
            content: {
                en: "Users must use CPU, RAM, and bandwidth within reasonable limits according to the selected service plan. Overuse of resources that causes server overload may result in temporary service restriction or termination without prior notice.",
                id: "Pengguna harus menggunakan CPU, RAM, dan bandwidth dalam batas yang wajar sesuai dengan paket layanan yang dipilih. Penggunaan sumber daya yang berlebihan hingga menyebabkan kelebihan beban server dapat menyebabkan pembatasan atau penghentian layanan sementara tanpa pemberitahuan terlebih dahulu."
            }
        },
        {
            title: {
                en: "2. Payment Policy and Service Termination",
                id: "2. Kebijakan Pembayaran dan Penghentian Layanan"
            },
            content: {
                en: "Hosting services are based on prepaid subscription terms. If payment is not received within 7 days of the renewal due date, the server or related services may be deactivated, and all stored data could be permanently deleted.",
                id: "Layanan hosting diberikan berdasarkan syarat berlangganan yang telah dibayar di muka. Jika pembayaran tidak diterima dalam 7 hari setelah jatuh tempo, server atau layanan terkait dapat dinonaktifkan, dan semua data yang tersimpan mungkin dihapus secara permanen."
            }
        },
        {
            title: {
                en: "3. User Privacy",
                id: "3. Privasi Pengguna"
            },
            content: {
                en: "We respect user privacy and are committed to safeguarding data confidentiality. User account security is the user's responsibility, and any activity from the user account is solely the user's responsibility.",
                id: "Kami menghormati privasi pengguna dan berkomitmen untuk menjaga kerahasiaan data. Keamanan akun pengguna adalah tanggung jawab pengguna, dan segala aktivitas yang berasal dari akun pengguna sepenuhnya menjadi tanggung jawab pengguna."
            }
        },
        {
            title: {
                en: "4. Limitation of Liability",
                id: "4. Batasan Tanggung Jawab"
            },
            content: {
                en: "The hosting provider is not responsible for damages, data loss, or other losses due to server disruptions, system failures, cyber-attacks, or misuse of the service.",
                id: "Penyedia layanan hosting tidak bertanggung jawab atas kerusakan, kehilangan data, atau kerugian lainnya akibat gangguan server, kegagalan sistem, serangan siber, atau penyalahgunaan layanan."
            }
        },
        {
            title: {
                en: "5a. Disk Usage and Content",
                id: "5a. Penggunaan Disk dan Konten"
            },
            content: {
                en: "Users are strictly prohibited from storing, distributing, or uploading illegal content, such as pornography, gambling activities, or any content related to criminal activities.",
                id: "Pengguna dilarang keras menyimpan, mendistribusikan, atau mengunggah konten ilegal, seperti pornografi, aktivitas perjudian, atau konten yang terkait dengan kegiatan kriminal."
            }
        },
        {
            title: {
                en: "5b. Abuse of Bugs or Vulnerabilities",
                id: "5b. Penyalahgunaan Bug atau Kerentanan"
            },
            content: {
                en: "Users are strictly prohibited from exploiting bugs, vulnerabilities, or system weaknesses in any manner. Any abuse detected may result in immediate service suspension or termination without prior notice, and further legal actions may be pursued.",
                id: "Pengguna dilarang keras menyalahgunakan bug, kerentanan, atau kelemahan sistem dalam bentuk apa pun. Penyalahgunaan yang terdeteksi dapat mengakibatkan penangguhan atau penghentian layanan secara langsung tanpa pemberitahuan sebelumnya, serta tindakan hukum lebih lanjut dapat dilakukan."
            }
        },
        {
            title: {
                en: "6. Downtime Policy and Compensation",
                id: "6. Kebijakan Downtime dan Kompensasi"
            },
            content: {
                en: "In case of downtime, Akashona Cloud users are entitled to service uptime compensation. The compensation will be provided as an extension of service time based on downtime duration or money refund.",
                id: "Jika terjadi downtime, pengguna layanan Akashona Cloud berhak mendapatkan kompensasi berupa penambahan masa aktif layanan, berdasarkan durasi downtime ataupun pengembalian dana."
            }
        },
        {
            title: {
                en: "7. Termination and Changes to Services",
                id: "7. Penghentian dan Perubahan Layanan"
            },
            content: {
                en: "The service provider reserves the right to terminate or amend service terms, with prior notification sent by email or posted on the official website.",
                id: "Penyedia layanan berhak untuk menghentikan atau mengubah ketentuan layanan dengan pemberitahuan sebelumnya melalui email atau diumumkan di situs web resmi."
            }
        },
        {
            title: {
                en: "8. Refund Policy",
                id: "8. Kebijakan Pengembalian Dana"
            },
            content: {
                en: "Refunds are only eligible under clear and valid reasons, and must be agreed upon by both parties. Users must provide a detailed explanation for the refund request, which will be evaluated before approval.",
                id: "Pengembalian dana hanya dapat dilakukan dengan alasan yang jelas dan valid, serta harus disepakati oleh kedua belah pihak. Pengguna harus memberikan penjelasan rinci untuk permintaan pengembalian dana, yang akan dievaluasi sebelum disetujui."
            }
        },
        {
            title: {
                en: "9. Legal Protection",
                id: "9. Perlindungan Hukum"
            },
            content: {
                en: "By using our services, users agree to indemnify and hold us harmless from any legal claims, lawsuits, damages, or penalties arising from misuse, illegal activities, or any breach of these Terms of Service.",
                id: "Dengan menggunakan layanan kami, pengguna setuju untuk membebaskan kami dari segala tuntutan hukum, gugatan, kerugian, atau hukuman yang timbul akibat penyalahgunaan, aktivitas ilegal, atau pelanggaran terhadap Syarat dan Ketentuan ini."
            }
        },
        {
            title: {
                en: "10. Legal Compliance",
                id: "10. Kepatuhan Hukum"
            },
            content: {
                en: "Users must comply with all applicable laws when using our services.",
                id: "Pengguna wajib mematuhi semua hukum yang berlaku saat menggunakan layanan kami."
            }
        },
        {
            title: {
                en: "11. Disclaimers",
                id: "11. Penafian"
            },
            content: {
                en: "The provider does not guarantee that the service will be uninterrupted, error-free, or completely secure.",
                id: "Penyedia layanan tidak menjamin bahwa layanan akan bebas dari gangguan, kesalahan, atau sepenuhnya aman."
            }
        },
    ]
};
