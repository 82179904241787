// shonahigh.js
export const shonahighcustomdata = [
  {
    title: 'Shona High (Docker)',
    description: 'Advanced Professional Docker Hosting. Using CPU from Intel Xeon E-2286G and SSD Storage.',
    packages: [
      {
        name: "Shona High 1",
        price: 5000,
        cpu_percent: 100,
        ram: 600,
        disk: 5120,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 2",
        price: 8000,
        cpu_percent: 200,
        ram: 1024,
        disk: 7168,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 3",
        price: 14000,
        cpu_percent: 200,
        ram: 2048,
        disk: 10240,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 4",
        price: 18000,
        cpu_percent: 400,
        ram: 3072,
        disk: 13312,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 5",
        price: 23000,
        cpu_percent: 400,
        ram: 4096,
        disk: 15360,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 6",
        price: 29000,
        cpu_percent: 600,
        ram: 6144,
        disk: 20480,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Shona High 7",
        price: 35000,
        cpu_percent: 800,
        ram: 8192,
        disk: 25600,
        backup_slots: 1,
        database_slots: 2,
      },
      {
        name: "Shona High 8",
        price: 50000,
        cpu_percent: 800,
        ram: 12288,
        disk: 30720,
        backup_slots: 2,
        database_slots: 2,
      },
      {
        name: "Shona High 9",
        price: 75000,
        cpu_percent: 800,
        ram: 16384,
        disk: 40960,
        backup_slots: 2,
        database_slots: 5,
      },
    ],
    faq: [
      {
        question: "Apa bedanya dengan Shona High biasa?",
        answer: "Hosting jenis ini hanya dibutuhkan untuk yang membutuhkan hosting berbasis docker. Misalnya NodeJS, ataupun macam macam Databases.",
      },
      {
        question: "Apa bisa custom apapun pada bagian dockernya?",
        answer: "Ya, anda dapat merequest apapun, misalnya Docker Image atau Software yang diinstall.",
      },
      {
        question: "Shona High menggunakan CPU apa?",
        answer: "Kami menggunakan CPU Intel Xeon Intel E-2286G yang membuat server anda berjalan mulus.",
      },
      {
        question: "Storage apa yang digunakan pada paket ini?",
        answer: "Kami menggunakan SSD Sata pada paket ini. Kecepatan Read/Write hingga 650MB/s",
      },
      {
        question: "Apa bisa diperpanjang?",
        answer: "Tentu saja server anda dapat diperpanjang kapanpun melalui menu setting di server.",
      },
    ],
  },
];
